import React, { useState, useEffect, useRef, useMemo } from "react"
import Layout from "../components/layout"
import { v4 } from "uuid"
import API from "../services/api"
import * as styles from "../css/pdf-processing.module.css"
import { useQuery, useLazyQuery, useMutation } from "@apollo/client"
import { createPIDMutation } from "../mutations/createCPT"
import FileView from "../components/FileView/FileView"
import getUploadedPDFQuery from "../query/getUploadedPdf"
import axios from "axios"

const PDFProcessor = () => {
  const [file, setFile] = useState("")
  const [uploadFileData, setUploadFileData] = useState({})
  const [uploadStatus, setUploadStatus] = useState(false)
  const [uploadedFiles, setUploadedFiles] = useState({})

  const uploadButton = useRef(null)

  const onButtonClick = () => {
    uploadButton.current.click()
  }

  const handleChange = e => {
    const fileUploaded = e.target.files[0]
    setFile(fileUploaded)
    uploadPDF(fileUploaded)
  }

  const uploadPDF = async file => {
    const formData = new FormData()
    formData.append("file", file, file.name)
    const { data } = await API.post("wp/v2/media", formData)
    setUploadFileData(data)
    uploadPDFMutation()
  }

  const processPDF = async cptID => {
    const params = {
      url: uploadFileData.source_url,
      cpt_id: cptID,
    }

    const { data: extractionOutput } = await axios.get(
      "https://139.59.37.149:5000/extract-text",
      { params }
    )

    console.log(extractionOutput)
  }

  const attachMediaToCPT = async (mediaID, cptID) => {
    const { data } = await API.post("sce/attach-media/cpt", {
      field_name: "input_pdf",
      media_id: mediaID,
      cpt_id: cptID,
    })
    setUploadStatus(!uploadStatus)
  }

  const [uploadPDFMutation] = useMutation(createPIDMutation, {
    variables: {
      createPIDInput: {
        clientMutationId: v4(),
        title: file.name ? file.name.split(".")[0] : "",
        status: "PUBLISH",
      },
    },
    onCompleted: data => {
      const { createPID } = data
      attachMediaToCPT(uploadFileData.id, createPID.pID.databaseId)
      processPDF(createPID.pID.databaseId)
    },
    onError: error => {
      console.log(error)
    },
  })

  const { data, loading: getUploadedPDFLoading } = useQuery(
    getUploadedPDFQuery,
    {
      pollInterval: 500,
    }
  )

  const fileData = useMemo(() => {
    if (data) {
      return data.pIDs.nodes.map(file => {
        return {
          title: file.title,
          status: file.pid.status,
          timestamp: file.date,
        }
      })
    } else {
      return []
    }
  }, [data])

  console.log(fileData)
  return (
    <Layout>
      <input
        type="file"
        accept=".pdf"
        onChange={handleChange}
        style={{ display: "none" }}
        ref={uploadButton}
      />
      <button onClick={onButtonClick}>Upload File</button>
      <FileView data={fileData} />
    </Layout>
  )
}

export default PDFProcessor
