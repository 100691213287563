const column = [
  {
    Header: "Title",
    accessor: "title", // accessor is the "key" in the data
    cName: "title",
  },
  {
    Header: "Status",
    accessor: "status",
    cName: "status",
  },
  {
    Header: "Timestamp",
    accessor: "timestamp",
    cName: "timestamp",
  },
]

export default column
