import { gql } from "@apollo/client"

const getUploadedPDFQuery = gql`
  query getUploadedPDFQuery {
    pIDs {
      nodes {
        title
        date
        databaseId
        pid {
          inputPdf {
            title
            databaseId
            fileSize
            link
          }
          status
        }
      }
    }
  }
`


export default getUploadedPDFQuery
