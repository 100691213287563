import React, { useMemo } from "react"
import { useTable } from "react-table"
import * as styles from "../../css/pdf-processing.module.css"
import column from "./columns"

const FileView = props => {
  const columns = React.useMemo(() => column, [])

  const data = React.useMemo(() => props.data, [props.data])

  const tableInstance = useTable({ columns, data })

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    tableInstance

  const print = () => {
    headerGroups.forEach(headerGroup => {
      headerGroup.headers.forEach(column => {
        console.log(column)
      })
    })

    rows.forEach(row => {
      prepareRow(row)
      row.cells.forEach(cell => {
        console.log(cell.column.cName)
      })
    })
  }

  // print()

  return (
    // apply the table props
    <table {...getTableProps()} className={styles.table}>
      <thead>
        {
          // Loop over the header rows
          headerGroups.map(headerGroup => (
            // Apply the header row props
            <tr {...headerGroup.getHeaderGroupProps()}>
              {
                // Loop over the headers in each row
                headerGroup.headers.map(column => (
                  // Apply the header cell props
                  <th
                    {...column.getHeaderProps()}
                    className={styles[column.cName]}
                  >
                    {
                      // Render the header
                      column.render("Header")
                    }
                  </th>
                ))
              }
            </tr>
          ))
        }
      </thead>
      {/* Apply the table body props */}
      <tbody {...getTableBodyProps()}>
        {
          // Loop over the table rows
          rows.map(row => {
            // Prepare the row for display
            prepareRow(row)
            return (
              // Apply the row props
              <tr {...row.getRowProps()}>
                {
                  // Loop over the rows cells
                  row.cells.map(cell => {
                    // Apply the cell props
                    return (
                      <td
                        {...cell.getCellProps()}
                        className={styles[cell.column.cName]}
                      >
                        {
                          // Render the cell contents
                          cell.render("Cell")
                        }
                      </td>
                    )
                  })
                }
              </tr>
            )
          })
        }
      </tbody>
    </table>
  )
}

export default FileView
