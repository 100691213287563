import { gql } from "@apollo/client"

export const createPIDMutation = gql`
  mutation createPIDMutation($createPIDInput: CreatePIDInput!) {
    createPID(input: $createPIDInput) {
      pID {
        id
        databaseId
        title
      }
    }
  }
`
